// Load jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

// load fonts
WebFont.load({
    google: { // add &display=swap to end of ONLY END font family, not every single one, other it doesnt load those fonts after
        families: ['Prompt:400,700&display=swap']
    },
    //,
    // typekit: {
    // id: 'XXXX'
    // },
    active: function() { // helps stop flash of unstyled text
        sessionStorage.fontsLoaded = true 
    }
});

// Load BS
require('bootstrap');

//Load Libraries
// require('flatpickr/dist/flatpickr.min');
require('magnific-popup/dist/jquery.magnific-popup.min');
require('objectFitPolyfill/dist/objectFitPolyfill.min');
require('select2/dist/js/select2.full.min');
require('slick-slider/slick/slick.min');

//Load Modules
require('./modules/accordion');
require('./modules/animatein');
require('./modules/formInputs');
require('./modules/gravityForms');
require('./modules/localVideoBlock');
require('./modules/menu');
// require('./modules/modal');
require('./modules/shareSocial');
// require('./modules/sideMenu');
